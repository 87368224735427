import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subject, takeUntil } from 'rxjs';
import { AppRoutes } from 'src/app/app.routes.const';
import { TRACK_TYPES } from 'src/app/common/constants/track-types.const';
import { ETurn } from 'src/app/common/enums/eTurn.enum';
import {
  TrackComponentInfo,
  TrackDetails,
} from 'src/app/configuration/models/track-details.interface';
import { AppLoadingService } from 'src/app/core/services/app-loading.service';
import { NavigationHistoryService } from 'src/app/core/services/navigation-history/navigation-history.service';
import { AppLayoutService } from 'src/app/layout/service/app.layout.service';
import {
  Configuration,
  ConfigurationVersion,
  VersionStatus,
} from '../models/configuration.model';
import { EditorModeService } from '../services/editor-mode/editor-mode.service';
import { ProjectConfigurationService } from '../services/project-configuration/project-configuration.service';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss'],
})
export class TrackComponent implements OnInit, OnDestroy {
  @ViewChild('dt') dt: Table | undefined;
  @ViewChild('tableWrapper') tableWrapper: ElementRef;
  @ViewChild('commentsSection') commentsSection: ElementRef;

  trackId: string;
  projectId: string;
  configId: string;
  versionId: string;
  currentConfiguration: Configuration;
  currentVersion: ConfigurationVersion;
  track: TrackDetails;
  isTrackEditable = false;
  editingMode = false;
  trackTypes = TRACK_TYPES;
  eTurn = ETurn;

  showSkeleton: { [key: string]: boolean } = {};

  comments: any[] = []; //FIXME: Should be changed according to API

  private readonly _destroying$ = new Subject<void>();

  get supertrakAcademyCount(): number {
    return this.track.specification.superTrakAcademyTrainingCount;
  }

  get superTrakSyncLicense(): boolean {
    return this.track.specification.includeOmniTrakLicense;
  }

  get isSoftwareIncluded(): boolean {
    return !!this.supertrakAcademyCount || this.superTrakSyncLicense;
  }

  get isVersionSubmitted(): boolean {
    return this.currentVersion.rfqStatus !== VersionStatus.Draft;
  }

  get isBaseFrameIncluded(): boolean {
    return this.track.specification.includeStandardBaseFrames;
  }

  get isOverUnder(): boolean {
    return this.track.specification.eTurn === ETurn.Curve500Low;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private projectConfigurationService: ProjectConfigurationService,
    private messageService: MessageService,
    private appLoadingService: AppLoadingService,
    private editorModeService: EditorModeService,
    private layoutService: AppLayoutService,
    private navHistoryService: NavigationHistoryService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this._destroying$))
      .subscribe(({ versionId }) => {
        this.versionId = versionId;
      });

    this.route.params
      .pipe(takeUntil(this._destroying$))
      .subscribe(({ configId, trackId }) => {
        this.configId = configId;
        this.trackId = trackId;

        const configuration = this.projectConfigurationService.getConfiguration(
          this.configId
        );

        const projectId = configuration?.projectId;

        if (projectId) {
          this.projectId = projectId;
        }

        if (configuration) {
          this.currentConfiguration = configuration;
          const configVersion =
            this.currentConfiguration.configurationVersions.find((version) => {
              return version.id === this.versionId;
            });

          if (configVersion) {
            this.currentVersion = configVersion;
          }
        }
      });

    this.track = this.route.snapshot.data[0];
    this.track.components.forEach((component) => {
      this.onImageLoadStart(component);
    });

    this.checkIfConfigEditable();

    this.editorModeService.onBehalfOfUser$
      .pipe(takeUntil(this._destroying$))
      .subscribe((user) => {
        this.isTrackEditable = this.isAvailableForEditing();
      });
  }

  ngOnDestroy(): void {
    this._destroying$.unsubscribe();
  }

  isComponentExpandable(component: TrackComponentInfo): boolean {
    return !!component.description || !!component.comments;
  }

  checkIfConfigEditable(): void {
    if (!this.versionId) {
      return;
    }

    if (!this.projectConfigurationService.configurations$.value.length) {
      this.retrieveConfigurationVersion();
      return;
    }

    this.isTrackEditable = this.isAvailableForEditing();
  }

  openTrackDesigner(): void {
    this.confirmationService.confirm({
      message:
        'Using the Track Designer to edit will overwrite your existing track.',
      header: 'Edit Track',
      icon: 'pi pi-info-circle',
      acceptLabel: 'Continue',
      rejectLabel: 'Cancel',
      accept: () => {
        this.router.navigate(
          [AppRoutes.TrackDesigner.fullPath(this.configId, this.trackId)],
          {
            queryParams: {
              projectId: this.projectId,
              versionId: this.versionId,
            },
          }
        );
      },
    });
  }

  retrieveConfigurationVersion(): void {
    this.appLoadingService.startLoadingBar();
    this.projectConfigurationService
      .requestConfigVersionById(this.versionId)
      .pipe(takeUntil(this._destroying$))
      .subscribe({
        next: (res) => {
          this.isTrackEditable = this.isAvailableForEditing();
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error requesting configuration version.',
            detail: error.status + ': ' + error.title,
          });
          this.appLoadingService.stopLoadingBar();
        },
        complete: () => {
          this.appLoadingService.stopLoadingBar();
        },
      });
  }

  onBack(): void {
    this.navHistoryService.back(
      AppRoutes.ProjectDetails.fullPath(this.projectId),
      {
        versionId: this.versionId,
      }
    );
  }

  handleImageError(component: any): void {
    component.imageUrl = 'assets/images/SuperTrak Gen3 Small.jpg';
  }

  onImageLoad(component: any): void {
    this.showSkeleton[component.name] = false;
  }

  onImageLoadStart(component: any): void {
    this.showSkeleton[component.name] = true;
  }

  isAvailableForEditing(): boolean {
    return (
      (this.editorModeService.isEditorMode
        ? this.currentConfiguration.ownerId !== this.currentVersion.createdById
        : this.currentConfiguration.ownerId ===
          this.currentVersion.createdById) &&
      this.currentVersion.rfqStatus === VersionStatus.Draft
    );
  }

  showComments(): void {
    this.layoutService.setScrollTop(
      this.commentsSection.nativeElement.offsetTop
    );
  }
}
