<p-button icon="pi pi-arrow-left"
          styleClass="w-5rem"
          (click)="onBack()"></p-button>
<ng-container *ngIf="track">
  <h4 class="m-0 mt-2">Track Details</h4>
  <div
       class="flex w-full py-2 relative transition-all transition-duration-200 align-items-center justify-content-between">
    <p-card class="w-full p-0">
      <h2 class="flex-none m-0">{{ track.name | titlecase }}</h2>
      <div class="flex flex-column flex-grow-1 m-0 align-items-end px-4">
        <h5 class="
           m-0">{{ trackTypes[track.specification.eTurn].trackDesignerLabel }}</h5>
        <p class="m-0">Length: {{ track.specification.length}}</p>
        <p *ngIf="track.specification.eTurn === eTurn.Curve90"
           class="m-0">Width: {{ track.specification.width}}</p>
        <p class="m-0">Shuttles: {{ track.specification.shuttleCount}}</p>
        <p class="m-0">Power Supplies: {{ track.specification.controlPanelInputVoltage }}</p>
      </div>
      <div class="flex flex-column align-items-start w-11rem">
        <button pButton
                pRippl
                *ngIf="isTrackEditable"
                type="button"
                class="w-full"
                label="Edit Track"
                (click)="openTrackDesigner()"></button>
        <button pButton
                pRipple
                type="button"
                label="Comments"
                (click)="showComments()"
                icon="pi pi-comment"
                class="p-button-outlined p-button-info w-full my-2"></button>
      </div>
    </p-card>
  </div>
  <div class="flex w-full transition-all py-2 transition-duration-200"
       #tableWrapper>
    <p-table #dt
             class="w-full"
             [value]="track.components"
             [globalFilterFields]="['name', 'componentNumber']"
             dataKey="id">
      <ng-template pTemplate="header">
        <tr>
          <th class="col-1"></th>
          <th class="col-2 text-center font-semibold text-lg">Image</th>
          <th class="col-4 text-center font-semibold text-lg"
              pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
          <th class="col-3 text-center font-semibold text-lg"
              pSortableColumn="componentNumber">Part # <p-sortIcon field="name"></p-sortIcon></th>
          <th class="col-2 text-center font-semibold text-lg">Quantity</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th>
            <p-columnFilter field="name"
                            placeholder="Search by Name"
                            [showMenu]="false"
                            matchMode="contains">
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="componentNumber"
                            placeholder="Search by Part #"
                            [showMenu]="false"
                            matchMode="contains">
            </p-columnFilter>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body"
                   let-component
                   let-expanded="expanded">
        <tr>
          <td>
            <button type="button"
                    pButton
                    pRipple
                    *ngIf="isComponentExpandable(component)"
                    [pRowToggler]="component"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td class="text-center">
            <img class="max-w-7rem max-h-7rem"
                 [ngClass]="{
                  'hidden': showSkeleton[component.name]
                 }"
                 [src]="component.imageUrl"
                 [alt]="component.name"
                 (load)="onImageLoad(component)"
                 (error)="handleImageError(component)" />

            <p-skeleton *ngIf="showSkeleton[component.name]"
                        class="flex justify-content-center"
                        size="7rem"></p-skeleton>
          </td>
          <td class="text-center text-xl font-semibold">{{ component.name }}</td>
          <td class="text-center">{{ component.componentNumber }}</td>
          <td class="text-center text-xl">{{ component.quantity }}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion"
                   let-component>
        <tr>
          <td colspan="5">
            <div class="flex">
              <div class="col-2 col-offset-2">
                <img class="w-full max-w-16rem"
                     [src]="component.imageBlobUrl"
                     [alt]="component.name">
              </div>
              <div class="col-4">
                <p class="font-semibold">Description:</p>
                <p>{{ component.description }}</p>
              </div>
              <div class="col-4">
                <p class="font-semibold">Subcomponents:</p>
                <p>{{ component.comments }}</p>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div>
    <div class="flex col-12">
      <h3 class="my-2">Included with Your Track Configuration</h3>
    </div>

    <div class="col-12 software justify-content-around">
      <p-card class="software-item"
              *ngIf="supertrakAcademyCount"
              styleClass="p-2 flex flex-grow-1 flex-column">
        <div class="flex flex-grow-1">
          <div class="flex align-items-center justify-content-center col-5">
            <img class="w-full"
                 src="/assets/images/SupertrakAcademy-Logo.jpg"
                 alt="SuperTrak Academy Logo">
          </div>
          <div class="col-2">
            <p-divider layout="vertical"></p-divider>
          </div>
          <div class="flex flex-column flex-grow-1 justify-content-center col-5">
            <h4 class="text-center m-0">SuperTrak Academy</h4>
            <p class="text-center text-xl mt-4">People to train: <span class="font-semibold">{{ supertrakAcademyCount
                }}</span>
            </p>
          </div>
        </div>
      </p-card>

      <p-card class="software-item"
              *ngIf="superTrakSyncLicense"
              styleClass="p-2 flex flex-grow-1 flex-column">
        <div class="flex flex-grow-1">
          <div class="flex align-items-center justify-content-center col-5">
            <img class="w-full"
                 src="/assets/images/SuperTrak Gen3 Small.jpg"
                 alt="SuperTrak Academy Logo">
          </div>
          <div class="col-2">
            <p-divider layout="vertical"></p-divider>
          </div>
          <div class="flex flex-column flex-grow-1 justify-content-center col-5">
            <p class="text-center font-semibold text-2xl m-0">SuperTrak Sync License™</p>
          </div>
        </div>
      </p-card>

      <p-card class="software-item"
              *ngIf="isOverUnder || isBaseFrameIncluded"
              styleClass="p-2 flex flex-grow-1 flex-column">
        <div class="flex flex-grow-1">
          <div class="flex align-items-center justify-content-center col-5">
            <h4 class="text-center m-0">Assembly, Configuration, Functional Test</h4>
          </div>
          <div class="col-2">
            <p-divider layout="vertical"></p-divider>
          </div>
          <div class="flex flex-column flex-grow-1 justify-content-center col-5">
            <p class="text-center font-semibold text-2xl text-green-600 m-0">Included</p>
          </div>
        </div>
      </p-card>

      <p-card class="software-item"
              styleClass="p-2 flex flex-grow-1 flex-column">
        <div class="flex flex-grow-1">
          <div class="flex align-items-center justify-content-center col-5">
            <h4 class="text-center m-0">Shipping Crates</h4>
          </div>
          <div class="col-2">
            <p-divider layout="vertical"></p-divider>
          </div>
          <div class="flex flex-column flex-grow-1 justify-content-center col-5">
            <p class="text-center font-semibold text-2xl text-green-600 m-0">Included</p>
          </div>
        </div>
      </p-card>

      <p-card class="software-item"
              styleClass="p-2 flex flex-grow-1 flex-column">
        <div class="flex flex-grow-1">
          <div class="flex flex-column justify-content-center col-5">
            <p class="text-2xl m-0">PLC starter code library access for:</p>
            <ul class="font-bold text-2xl m-0 py-0">
              <li class="text-xl">Omron</li>
              <li class="text-xl">Siemens</li>
              <li class="text-xl">Allen-Bradley</li>
              <li class="text-xl">Beckhoff</li>
            </ul>
          </div>
          <div class="col-2">
            <p-divider layout="vertical"></p-divider>
          </div>
          <div class="flex flex-column flex-grow-1 justify-content-center col-5">
            <p class="text-center font-semibold text-2xl text-green-600 m-0">Included</p>
          </div>
        </div>
      </p-card>

      <p-card class="software-item"
              styleClass="p-2 flex flex-grow-1 flex-column">
        <div class="flex flex-grow-1">
          <div class="flex align-items-center justify-content-center col-5">
            <img class="w-full"
                 src="/assets/images/logos/TrakMasterSoftware.png"
                 alt="SuperTrak Academy Logo">
          </div>
          <div class="col-2">
            <p-divider layout="vertical"></p-divider>
          </div>
          <div class="flex flex-column flex-grow-1 justify-content-center col-5">
            <h4 class="text-center m-0">TrakMaster™ Software</h4>
          </div>
        </div>
      </p-card>
    </div>
  </div>

  <div #commentsSection>
    <app-track-comments [trackId]="trackId"
                        [currentConfiguration]="currentConfiguration"
                        [isVersionSubmitted]="isVersionSubmitted"
                        [currentVersion]="currentVersion"></app-track-comments>
  </div>

</ng-container>

<p-confirmDialog [style]="{
                    width: '50vw'
                  }"
                 [baseZIndex]="10000"
                 rejectButtonStyleClass="p-button-text"></p-confirmDialog>
